.layout {
    font-family: sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.mobile-layout {
  font-family: sans-serif;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
}

.chat-button{
    width: 30px;
    height: 30px;
    padding: 0px;
    margin: 0px;
    background-color: white;
    border: none;
    outline: none;
    cursor: pointer;
    position: fixed;
    bottom: 30vh;
    right: 0px;
    background-image: radial-gradient(white, transparent);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}